import { useEffect } from 'react';

const GoogleTagManager = () => {
  useEffect(() => {
    if (process.env.REACT_APP_VERCEL_ENV === 'production') {
      // Load GTM script
      const gtmScript = document.createElement('script');
      gtmScript.async = true;
      gtmScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-BTM4DQQMY4';
      document.head.appendChild(gtmScript);

      // Inline script
      const inlineScript = document.createElement('script');
      inlineScript.innerHTML = `
        console.log('Enabling Analytics');
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        
        gtag('config', 'G-BTM4DQQMY4');
      `;
      document.head.appendChild(inlineScript);
    }
  }, []);

  return null;
};

export default GoogleTagManager;
