// @mui
import { enUS, deDE } from '@mui/material/locale';

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: 'flagpack:gb-nir',
  },
  {
    label: 'German',
    value: 'de',
    systemValue: deDE,
    icon: 'flagpack:de',
  },
];

export const defaultLang = allLangs[0]; // English
