// ----------------------------------------------------------------------

export const paths = {
  root: '/',
  pathen: 'https://pathen.io/',
  contact: 'mailto:pablo@code-engine.net',
  tnc: '/terms-and-conditions',
  privacy: '/privacy-policy',
  imprint: '/imprint',
  cookiePolicy: '/cookie-policy',
};
