import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

//
import MainLayout from 'src/layouts/main/layout';
import { mainRoutes } from './main';
import { paths } from '../paths';

// ----------------------------------------------------------------------

const Home = React.lazy(() => import('src/pages/home'));
const TermsAndConditions = React.lazy(() => import('src/pages/terms-and-conditions'));
const PrivacyPolicy = React.lazy(() => import('src/pages/privacy-policy'));
const Imprint = React.lazy(() => import('src/pages/imprint'));
const CookiePolicy = React.lazy(() => import('src/pages/cookie-policy'));

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Home />,
    },

    // Main routes
    // ...mainRoutes,
    {
      path: paths.tnc,
      element: <TermsAndConditions />,
    },
    {
      path: paths.privacy,
      element: <PrivacyPolicy />,
    },
    {
      path: paths.imprint,
      element: <Imprint />,
    },
    {
      path: paths.cookiePolicy,
      element: <CookiePolicy />,
    },
    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
